import { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import { LandingPage } from "./components/LandingPage.jsx";
import AuthSignIn from "./components/auth/AuthSignIn";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    window.addEventListener("online", () =>
      toast.success("You Are Online Now")
    );
    window.addEventListener("offline", () =>
      toast.error("Please Check Your Internet")
    );
  });

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={AuthSignIn}></Route>
          <Route path="/signin" component={AuthSignIn}></Route>
          <Route path="/home" component={LandingPage}></Route>
        </Switch>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;

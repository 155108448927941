import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import productImage from "../../assets/background.jpeg";
import "./auth.css";
import Loader from "../loader/Loader";
import { baseURL } from "../../api-routes/ApiRoutes";
// import { baseURL } from "../../core";

const parentGrind = {
  backgroundImage: `url(${productImage})`,
  backgroundRepeat: "no-repeat",
  backgroundColor: (t) =>
    t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
  backgroundSize: "cover",
  backgroundPosition: "center",
};
const childGrind = {
  my: 8,
  // mx: 4,
  mx: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "11px",
  background: "#ffffffab",
  backdropFilter: "blur(10px)",
};
const boxContainer = {
  my: 8,
  mx: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.pakistanautomation.com.pk/">
        Pakistan Automation
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const history = useHistory();
  const url = `${baseURL}users/login`;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(!isValidEmail(event.target.value));
  };

  const isValidEmail = (email) => {
    // Use a regular expression to validate the email format
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  function onSubmit(event) {
    event.preventDefault();

    setIsLogin(true);
    setTimeout(() => {
      setIsLogin(false);
    }, 3000);

    if (!email.length || !password.length) {
      setIsLogin(false);
      toast.error("Please Fill All Fields");
      return;
    }

    axios
      .post(url, {
        email: email,
        password: password,
      })
      .then((res) => {
        handleToggle();
        // console.log("response from login >>>", res);
        localStorage.setItem("token", res.data.token);
        if (res.status === 201) {
          toast.success("Login successfully");
        }
        if (localStorage.getItem("token")) {
          setLoading(false);
          setTimeout(() => {
            history.push("/home");
            window.location.reload();
          }, 1);
        } else {
          setIsLogin(false);
        }
        console.log("No user found");
      })
      .catch((err) => {
        handleClose();
        // console.log("in catch", isLogin);
        console.log("error from catch >>>>>", err);
        setCheckError(true);
        if (err.status === 401) {
          toast.error("Authfailed in bcr wrong password");
        } else if (err.status === 404) {
          toast.error("User not found some issues in authentication");
        }
        toast.error(" Authentication failed.");
        setIsLogin(false);
      });
  }

  return (
    <>
      <div>
        {!loading ? (
          !checkError ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            ""
          )
        ) : (
          <Link to="/"></Link>
        )}
      </div>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid item xs={false} sm={12} md={12} sx={parentGrind}>
            <Grid
              item
              xs={10}
              sm={8}
              md={6}
              lg={4}
              component={Paper}
              elevation={6}
              square
              sx={childGrind}
            >
              <Box sx={boxContainer}>
                <div className="logo">
                  <img src="./icon/logo.png" alt="" />
                </div>
                <Typography component="h1" variant="h3" className="my-4">
                  Sign in
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <form onSubmit={onSubmit} noValidate>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      type="email"
                      value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={handleEmailChange}
                      error={emailError}
                      helperText={
                        emailError ? "Please enter a valid email address" : ""
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      placeholder="********"
                      value={password}
                      inputProps={{ maxLength: 12 }}
                      onChange={handlePassword}
                    />
                    {isLogin ? (
                      <div className="w-100 my-3">
                        <div className="w-25 mx-auto">
                          <Loader />
                        </div>
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="bg-success"
                        style={{
                          borderRadius: "11px",
                          width: "100%",
                          color: "white",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          textAlign: "center",
                        }}
                      >
                        Sign In
                      </Button>
                    )}
                  </form>

                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

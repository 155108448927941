import React, { useState, useEffect } from "react";
import Home from "./home/Home";
import { Navbar, Container, Offcanvas, Nav } from "react-bootstrap";
import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import jwt from "jsonwebtoken";
import UserProfile from "./profile/UserProfile";
import CreateUsers from "./create-user/CreateUsers";

import logo from "../assets/images/white.png";

const closeBtnStyle = {
  display: "inline-block",
  fontWeight: "400",
  lineHeight: "1.5",
  color: "#212529",
  textAlign: "center",
  textDecoration: "none",
  verticalAlign: "middle",
  cursor: "pointer",
  userSelect: "none",
  backgroundColor: "white",
  border: "1px solid transparent",
  padding: "0.375rem 0.75rem",
  fontSize: "1rem",
  borderRadius: "0.25rem",
  transition:
    "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
};

export const LandingPage = () => {
  const [expanded, setExpanded] = useState(false);
  const [isAdmin, setIsAdmin] = useState("");
  const match = useRouteMatch();
  const history = useHistory();

  const HandleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    if (localStorage.getItem("token")) {
      console.log("here");
    } else {
      history.push("/signin");
    }
  };
  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    const isAdmin = decoded.payload.isAdmin;
    setIsAdmin(isAdmin);
  }, []);

  return (
    <div className="main-container" style={{ height: "100vh" }}>
      <header className="header_area">
        <div className="main_menu" id="mainNav">
          <Navbar expand={false} collapseOnSelect expanded={expanded}>
            <Container fluid>
              <Navbar.Brand>
                <Link to="/home" className="navbar-brand logo_h">
                  <img
                    className="logo_h"
                    src={logo}
                    width="200"
                    alt=""
                    style={{ zIndex: "99%" }}
                  />
                </Link>
              </Navbar.Brand>

              <Navbar.Toggle
                style={{ margin: "1rem", background: "white" }}
                aria-controls="offcanvasNavbar"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />

              <Navbar.Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
                className="bg-canvas"
              >
                <Offcanvas.Header
                  closeButton
                  onClick={() => setExpanded(false)}
                  className="text-white"
                >
                  <Offcanvas.Title id="offcanvasNavbarLabel">
                    {/* Menu */}
                    <button
                      onClick={() => setExpanded(false)}
                      style={closeBtnStyle}
                    >
                      Close
                    </button>
                  </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link>
                      <Link
                        onClick={() => setExpanded(false)}
                        className="nav-link active main_btn"
                        to="/home"
                      >
                        Home
                      </Link>
                    </Nav.Link>
                    {isAdmin === "Admin" ? (
                      <>
                        <Nav.Link>
                          <Link
                            onClick={() => setExpanded(false)}
                            className="nav-link main_btn"
                            to={match.url + "/createUsers"}
                          >
                            User Setting
                          </Link>
                        </Nav.Link>
                      </>
                    ) : null}

                    <Nav.Link>
                      <Link
                        onClick={() => setExpanded(false)}
                        className="nav-link main_btn"
                        to={match.url + "/accountDetails"}
                      >
                        My Profile
                      </Link>
                    </Nav.Link>
                    <span
                      className="nav-link submit_btn"
                      style={{ cursor: "pointer", marginTop: "5rem" }}
                      onClick={HandleLogout}
                    >
                      Logout
                    </span>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </header>

      <Switch>
        <Route exact path={"/home"}>
          <Home />
        </Route>
        <Route path={match.url + "/accountDetails"}>
          <UserProfile />
        </Route>
        <Route path={match.url + "/createUsers"}>
          <CreateUsers />
        </Route>
      </Switch>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import jwt from "jsonwebtoken";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import SelectSearch, { fuzzySearch } from "react-select-search";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
// Dialof Import
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
// test icons
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import coverImage from "../../assets/home-banner2.jpg";

import "./CreateUser.css";
import { userRequest } from "../../api-routes/ApiRoutes";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "120%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  button: {
    backgroundColor: "green",
    color: "white",
  },
  paper: {
    height: "100%",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
}));
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MTStyle = {
  marginTop: "10px",
  whiteSpace: "nowrap",
  maxWidth: "150%",
  tableLayout: "fixed",
  backgroundImage: `url(${coverImage})`,
  backgroundPosition: "center",
  backgroundrepeat: "no-repeat",
  backgroundSize: "cover",
  color: "black",
}

const CreateUsers = () => {
  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [ofsRole, setOfsRole] = useState("");
  const [bosRole, setBosRole] = useState("");
  const [defectRole, setDefectRole] = useState("");
  const [clRole, setClRole] = useState("");
  const [cilRole, setCILRole] = useState("");
  const [systemAdmin, setSystemAdmin] = useState("");
  const [isOFS, setIsOFS] = useState(false);
  const [isBOS, setIsBOS] = useState(false);
  const [isDH, setIsDH] = useState(false);
  const [isCL, setIsCL] = useState(false);
  const [isCIL, setIsCIL] = useState(false);
  const [isHC, setIsHC] = useState(false);
  const [isDDS, setIsDDS] = useState(false);
  const [is5S, setIs5S] = useState(false);
  const [isAsset, setIsAsset] = useState(false);

  //mehak edit start
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };

  const handleCloseR = () => {
    setOpenR(false);
  };
  //mehak edit end

  const classes = useStyles();

  const [userData, setUsersData] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(3);
  const [departName, setDepartName] = useState("");
  const [userRole, setUserRole] = useState(0);

  const clearForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setAdmin("");
  };

  const getUserData = async () => {
    const fetchedData = await userRequest.get(`/users/getUsers`);
    setUsersData(fetchedData.data);
    // setLoading(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // add validation form
      setIsDisabled(false);
      setValidated(true);
      toast.error("Please fill all fields");
      return;
    }
    if (
      username.length === 0 &&
      email.length === 0 &&
      password.length === 0 &&
      admin === 3
    ) {
      // alert("please write area name")
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      setIsDisabled(false);

      return;
    }
    if (departName.length < 1) {
      toast.warn("Select User Department");
      return;
    }
    setValidated(true);
    setIsDisabled(true);

    userRequest
      .post(`/users/userCreation`, {
        email: email,
        username: username,
        password: password,
        departName: departName,
        AssignedOFS: isOFS,
        AssignedEdefect: isDH,
        AssignedBOS: isBOS,
        AssignedCL: isCL,
        AssignedCIL: isCIL,
        AssignedHC: isHC,
        AssignedDDS: isDDS,
        Assigned5S: is5S,
        AssignedAsset: isAsset,
        ofsRole: ofsRole,
        bosRole: bosRole,
        defectRole: defectRole,
        isAdmin: systemAdmin,
        clRole: clRole,
        cilRole: cilRole,
      })
      .then((res) => {
        toast.success("Inserted successfully !");
        setIsDisabled(false);
        if (res.data.results === "Success") {
          clearForm();
          setOpenR(false);
          getUserData();
          setValidated(false);
        } else {
          setOpenR(true);
          toast.error("Error !");
        }
      })
      .catch((res) => {
        if (res.response.status === 409) {
          // alert("User already Exist");
          toast.error("User already Exist");
          setIsDisabled(false);
          return;
        } else {
          toast.error("Error !");
        }
      });
  };
  //state for fetching data
  const [departmentData, setDepartmentData] = useState([]);
  //  Fetching Data For Departements
  const getdepartments = async () => {
    const fetchedData = await userRequest.get(
      "https://ofsb.finehhdigital.com/api/department/selectDepartment"
    );
    setDepartmentData(fetchedData.data.data);
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    const isAdmin = decoded.payload.isAdmin;
    setUserRole(isAdmin);
    getUserData();
    getdepartments();
  }, []);

  var departmentNameObject = departmentData.reduce(function (acc, cur, i) {
    acc[cur.DepartName] = cur.DepartName;
    return acc;
  }, {});
  //for select Search
  var departmentOption = departmentData.map((item) => ({
    name: item.DepartName,
    value: item.DepartName,
  }));

  const columns = [
    { field: "username", title: "UserName" },
    { field: "email", title: "Email" },
    {
      field: "isAdmin",
      title: "System Role",
      lookup: { Admin: "Admin", User: "User" },
    },
    { field: "DepartName", title: "Department", lookup: departmentNameObject },
    {
      field: "AssignedEDefect",
      title: "Assigned DH",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedOFS",
      title: "Assigned OFS",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedBOS",
      title: "Assigned BOS",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedCL",
      title: "Assigned CL",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedECIL",
      title: "Assigned CIL",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedHC",
      title: "Assigned HC",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedDDS",
      title: "Assigned DDS",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "Assigned5S",
      title: "Assigned 5S",
      lookup: { 1: "True", 0: "False" },
    },
    {
      field: "AssignedAsset",
      title: "Assigned Asset",
      lookup: { 1: "True", 0: "False" },
    },

    {
      field: "defectRole",
      title: "DH Role",
      lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
    },
    {
      field: "ofsRole",
      title: "OFS Role",
      lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
    },
    {
      field: "bosRole",
      title: "BOS Role",
      lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
    },
    {
      field: "clRole",
      title: "CL Role",
      lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
    },
    {
      field: "cilRole",
      title: "CIL Role",
      lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
    },
    { field: "password", title: "Password" },
  ];

  return (
    <div className="p-0 m-0 container-fluid DHContainer h-100">
      <section className="home_banner_area h-100">
        <div className="px-4 banner_inner">
          <div>
            <Button
              style={{ float: "right", marginTop: "2.5rem" }}
              type="button"
              onClick={handleOpenR}
              variant="contained"
              className={`addUserBtn ${classes.button}`}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Users
            </Button>
            <Dialog
              fullScreen={fullScreen}
              open={openR}
              onClose={handleCloseR}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                style: {
                  background: "linear-gradient(to bottom, #039BE5, #22B2F4)",
                  width: "600px",
                },
              }}
            >
              <DialogTitle id="responsive-dialog-title">
                <div className="test-TwoBtn">
                  <div>
                    {isDisabled ? (
                      <Button
                        type="submit"
                        className="dialog-btn tick-btn-abc"
                        disabled
                      >
                        <CheckIcon />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        onClick={submitHandler}
                        className="dialog-btn tick-btn-abc"
                      >
                        <CheckIcon />
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button
                      className="dialog-btn close-btn-abc"
                      onClick={() => handleCloseR()}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                </div>
                <Typography
                  component="h1"
                  variant="h5"
                  className="typography text-center text-white border-bottom rounded"
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontFamily: "ariel",
                  }}
                >
                  Create Users
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Form noValidate validated={validated}>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="8"
                      className="w-100"
                      controlId="validationCustom01"
                    >
                      <Form.Label>Name :</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        className="w-100"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="8"
                      className="w-100"
                      controlId="validationCustom02"
                    >
                      <Form.Label>Email :</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="email"
                        placeholder="Email"
                        className="w-100"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="8"
                      className="w-100"
                      controlId="validationCustom03"
                    >
                      <Form.Label>Password :</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="password"
                        placeholder="password"
                        className="w-100"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid password.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="8"
                      className="w-100"
                      controlId="validationCustom04"
                    >
                      <Form.Label>Depart Name :</Form.Label>
                      {/* <Form.Control> */}
                      <SelectSearch
                        id="department"
                        value={departName}
                        onChange={(e) => setDepartName(e)}
                        options={departmentOption}
                        search
                        filterOptions={fuzzySearch}
                        placeholder="Select your Area"
                      />
                      {/* </Form.Control> */}
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Depart Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* Check for OFS user */}
                    <Typography
                      component="h1"
                      variant="h5"
                      className="typography text-center text-white border-bottom rounded"
                      style={{
                        color: "black",
                        fontWeight: "bolder",
                        fontFamily: "ariel",
                      }}
                    >
                      User Assigned To
                    </Typography>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="OFS"
                        onChange={(e) => setIsOFS((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="Defect Handling"
                        onChange={(e) => setIsDH((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="BOS"
                        onChange={(e) => setIsBOS((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="CL"
                        onChange={(e) => setIsCL((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="CIL"
                        onChange={(e) => setIsCIL((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="HC"
                        onChange={(e) => setIsHC((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="DDS"
                        onChange={(e) => setIsDDS((preValue) => !preValue)}
                      />
                    </Form.Group>
                    <Form.Group className="my-1">
                      <Form.Check
                        label="5S"
                        onChange={(e) => setIs5S((preValue) => !preValue)}
                      />
                    </Form.Group>

                    <Typography
                      component="h1"
                      variant="h5"
                      className="typography text-center text-white border-bottom rounded"
                      style={{
                        color: "black",
                        fontWeight: "bolder",
                        fontFamily: "ariel",
                      }}
                    >
                      User Role
                    </Typography>
                    <div className="UserRoleRadio">
                      <div>
                        <div>
                          <Form.Label>System Admin:</Form.Label>
                        </div>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions4"
                            id="inlineRadio1"
                            defaultValue="Admin"
                            onChange={(e) => setSystemAdmin(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions4"
                            id="inlineRadio2"
                            defaultValue="User"
                            onChange={(e) => setSystemAdmin(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="UserRoleRadio">
                      <div>
                        <div>
                          <Form.Label>OFS:</Form.Label>
                        </div>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions3"
                            id="inlineRadio1"
                            defaultValue="Admin"
                            onChange={(e) => setOfsRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Admin
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions3"
                            id="inlineRadio2"
                            defaultValue="SuperUser"
                            onChange={(e) => setOfsRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Line Leader
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions3"
                            id="inlineRadio3"
                            defaultValue="User"
                            onChange={(e) => setOfsRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            User
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="UserRoleRadio">
                      <div>
                        <div>
                          <Form.Label>BOS:</Form.Label>
                        </div>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio4"
                            defaultValue="Admin"
                            onChange={(e) => setBosRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Admin
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio5"
                            defaultValue="SuperUser"
                            onChange={(e) => setBosRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Line Leader
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio6"
                            defaultValue="User"
                            onChange={(e) => setBosRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            User
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="UserRoleRadio">
                      <div>
                        <div>
                          <Form.Label>Defect Handling:</Form.Label>
                        </div>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            id="inlineRadio7"
                            defaultValue="Admin"
                            onChange={(e) => setDefectRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Admin
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            id="inlineRadio8"
                            defaultValue="SuperUser"
                            onChange={(e) => setDefectRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Line Leader
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            id="inlineRadio9"
                            defaultValue="User"
                            onChange={(e) => setDefectRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            User
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="UserRoleRadio">
                      <div>
                        <div>
                          <Form.Label>Center Line:</Form.Label>
                        </div>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions5"
                            id="inlineRadio7"
                            defaultValue="Admin"
                            onChange={(e) => setClRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Admin
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions5"
                            id="inlineRadio8"
                            defaultValue="SuperUser"
                            onChange={(e) => setClRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Line Leader
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions5"
                            id="inlineRadio9"
                            defaultValue="User"
                            onChange={(e) => setClRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            User
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="UserRoleRadio">
                      <div>
                        <div>
                          <Form.Label>CIL:</Form.Label>
                        </div>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions6"
                            id="inlineRadio10"
                            defaultValue="Admin"
                            onChange={(e) => setCILRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Admin
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions6"
                            id="inlineRadio11"
                            defaultValue="SuperUser"
                            onChange={(e) => setCILRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Line Leader
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions6"
                            id="inlineRadio12"
                            defaultValue="User"
                            onChange={(e) => setCILRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            User
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </Row>
                </Form>
              </DialogContent>
            </Dialog>
            {/* Form Ended */}
          </div>
          {/* <div className="w-100"> */}
          <div
            className="w-100 material-tabl-style"
            style={{ marginTop: "5rem" }}
          >
            <MaterialTable
              icons={tableIcons}
              style={MTStyle}
              title="Users"
              columns={columns}
              data={userData}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...userData];
                      const index = oldData.id;
                      dataUpdate[index] = newData;
                      userRequest
                        .post(`/users/updateUser`, newData)
                        .then((res) => {
                          resolve();
                          getUserData();
                          toast.success("Update successfully !");
                        })
                        .catch((error) => {
                          reject()
                          toast.error("Server Error !");
                        });
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const index = oldData.id;
                      userRequest
                        .post(`/users/deleteUser`, {
                          id: index,
                        })
                        .then((res) => {
                          getUserData();
                          resolve()
                        })
                        .catch((error) => {
                          reject()
                          toast.error("Server Error");
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                rowStyle: (rowData) => {
                  if (rowData.isAdmin === "Admin") {
                    return {
                      background:
                        "linear-gradient(to right, #3A94A6 , #00dbde)",
                    };
                  }

                  return {};
                },
                filtering: true,
                exportButton: true,
                pageSizeOptions: [
                  5,
                  10,
                  20,
                  { value: userData?.length, label: "All" },
                ],
                headerStyle: {
                  backgroundColor: "#2E3B55",
                  color: "white",
                },
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateUsers;

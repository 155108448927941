import axios from "axios";

// const baseURL = 'http://192.168.100.5:5000/api/'
// export const baseURL = "http://localhost:5000/api/";
// export const baseURL = "https://actived.finehhdigital.com/api/";
export const baseURL = "https://backup-ad-b.finehhdigital.com/api/";

const token = localStorage.getItem("token");
export const userRequest = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

import React, { useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { Link } from "react-router-dom";
import { userRequest } from "../../api-routes/ApiRoutes";
import Loader from "../loader/Loader";

const anchorStyle = {
  color: "black",
  textDecoration: "none",
  fontFamily: "sans-serif",
};
const anchorStyle1 = {
  color: "white",
  textDecoration: "none",
  fontFamily: "sans-serif",
};
const paraStyle = {
  textAlign: "center",
  fontSize: "15px",
  height: "10vh",
  color: "white",
};
const userNameStyle = {
  marginLeft: "0.5rem",
  fontWeight: "bold",
  fontFamily: "inherit",
};

const mainDivStyle = {
  marginTop: "0.7rem",
  marginRight: "1.5rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "auto",
};

const accountDetailStyle = { textDecoration: "none", color: "white" };

const Home = () => {
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    let fd = await userRequest.post(`/users/userIndividualDatabyID`, {
      id: userId,
    });
    setUserData(fd.data?.data?.[0]);
  };

  useEffect(() => {
    const getitem = localStorage.getItem("token");
    const decoded = jwt.decode(getitem);
    setUserId(decoded.id);
    if (decoded?.id) {
      getUserData();
    }
  }, [userId]);

  return (
    <div>
      <section className="home_banner_area" id="home">
        <div className="main-container overflow-auto banner_inner">
          {" "}
          <div style={mainDivStyle} className="pt-2">
            <Link to="/home/accountDetails" style={accountDetailStyle}>
              {userData?.profileUrl ? (
                <div>
                  <img
                    width="50rem"
                    style={{ borderRadius: "5rem" }}
                    src={userData.profileUrl}
                    alt=""
                  ></img>
                </div>
              ) : (
                <div>
                  <img
                    width="50rem"
                    style={{ borderRadius: "5rem" }}
                    src="/icon/dummyuser.jpg"
                    alt=""
                  ></img>
                </div>
              )}
            </Link>
            <Link to="/home/accountDetails" style={accountDetailStyle}>
              <div>
                <span style={userNameStyle}>{userData?.username}</span>
              </div>
            </Link>
          </div>
          <div className="container d-flex flex-column">
            <div className="main_title">
              <h2 className="text-white">Fine Hygienic Holding</h2>
              <h3 className="text-white" style={{ fontFamily: "cursive" }}>
                Digital Store
              </h3>
            </div>
            {userData?.username ? (
              <div className=" cards">
                {userData?.AssignedOFS ? (
                  <div className="hvr-shadow-radial">
                    <a
                      // target="_blank"
                      // to={{
                      //   // pathname: `https://ofs.pakistanautomation.com.pk/?token=${localStorage.getItem(
                      //   pathname: `https://ofs.finehhdigital.com/?token=${localStorage.getItem(
                      //     "token"
                      //   )}`,
                      // }}
                      href={`https://ofs.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      style={anchorStyle}
                    >
                      <div className="col-lg-3 col-md-6  py-2 ">
                        <div
                          className="feature_item "
                          style={{
                            // background: "linear-gradient(100deg, #5AD0F2, #2DBDFA)",
                            background: "rgb(43 159 202)",
                          }}
                          // style={bgColor}
                        >
                          <h4 className="text-Card text-white">OFS</h4>
                          <p style={paraStyle}>Ongoing Feedback System</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedBOS ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://bos.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      style={anchorStyle}
                    >
                      <div className="col-lg-3 col-md-6  py-2 ">
                        <div
                          className="feature_item "
                          style={{
                            // background: "linear-gradient(100deg, #FF66F4, #7D7AF8)",
                            // background: "rgb(139 234 202)",
                            background: "rgb(73 216 168)",
                          }}
                          // style={bgColor}
                        >
                          <h4 className="text-Card text-white">BOS</h4>
                          <p style={paraStyle}>Behaviour Observation System</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedEDefect ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://defecthandling.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      style={anchorStyle}
                    >
                      <div className="col-lg-3 col-md-6  py-2 defect-handling-card">
                        <div
                          className="feature_item"
                          style={{
                            // background: "linear-gradient(100deg, #01F4EE, #01E2AD)",
                            background: "rgb(27 98 89)",
                          }}
                          // style={bgColor}
                        >
                          <h4 className="text-Card text-white">DH</h4>
                          <p style={paraStyle}>Defect Handling System</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedCL ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://cl.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      style={anchorStyle}
                      disabled
                    >
                      <div className="col-lg-3 col-md-6  py-2">
                        <div
                          className="feature_item"
                          style={{
                            // background: "linear-gradient(100deg, #FD6F2E, #F42474)",
                            background: "rgb(255, 148, 158)",
                          }}
                          // style={bgColor}
                        >
                          <h4 className="text-Card text-white">CL</h4>
                          <p style={paraStyle}>Center Line System</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedECIL ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://ecil.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      // className="main_btn_disabled mt-4 text-decoration-none"
                      style={anchorStyle}
                    >
                      <div className="col-lg-3 col-md-6  py-2">
                        <div
                          className="feature_item"
                          // style={{ background: "linear-gradient(100deg, #FD6F2E, #F42474)" }}
                          style={{
                            // background: "linear-gradient(135deg, #FFE500 0%, #01E2AD 100%)",
                            // background: "rgb(255, 215, 155)",
                            background: "rgb(196 162 110)",
                          }}
                          // style={bgColor}
                        >
                          <h4 className="text-Card text-white">CIL</h4>
                          <p style={paraStyle}>
                            Cleaning Inspection Lubrication
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {/* {userId === 37 || userId === 63 || userId === 69 || userId === 62 || userId === 348 ? ( */}
                {userData?.Assigned5S ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://fives.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      style={anchorStyle1}
                    >
                      <div className="col-lg-3 col-md-6  py-2">
                        <div
                          className="feature_item"
                          style={{
                            background: "rgb(84 110 170)",
                          }}
                        >
                          <h4 className="text-Card text-white">5S</h4>
                          <p style={paraStyle}>5S Audit</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedHC ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://healthf.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      // href={`http://localhost:3000/?token=${localStorage.getItem(
                      //   "token"
                      // )}`}
                      style={anchorStyle1}
                    >
                      <div className="col-lg-3 col-md-6  py-2">
                        <div
                          className="feature_item"
                          style={{
                            // background:"linear-gradient(135deg, #0c3b8b89 0%, #002564 100%)",
                            background: "rgb(115 113 120)",
                          }}
                          // style={bgColor}
                        >
                          <h4 className="text-Card text-white">HC</h4>
                          <p style={paraStyle}>DMS Health Check System</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedDDS ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://dds.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      // href={`http://localhost:3000/?token=${localStorage.getItem(
                      //   "token"
                      // )}`}
                      style={anchorStyle1}
                    >
                      <div className="col-lg-3 col-md-6  py-2">
                        <div
                          className="feature_item"
                          style={{
                            background:
                              "linear-gradient(135deg, #1256cc 0%, #2770ed 100%)",
                            // background: "rgb(115 113 120)",
                          }}
                        >
                          <h4 className="text-Card text-white">DDS</h4>
                          <p style={paraStyle}>
                            Daily Direction Setting
                            {/* <h4 className="text-white">
                            DDS
                          </h4> */}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
                {userData?.AssignedAsset ? (
                  <div className="hvr-shadow-radial">
                    <a
                      href={`https://asset.finehhdigital.com/?token=${localStorage.getItem(
                        "token"
                      )}`}
                      style={anchorStyle1}
                    >
                      <div className="col-lg-3 col-md-6  py-2">
                        <div
                          className="feature_item"
                          style={{
                            background: "#5CAAFC",
                          }}
                        >
                          <h4 className="text-Card text-white">Asset</h4>
                          <p style={paraStyle}>Tech Track System</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ): null}
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: 200 }}
              >
                <Loader />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Home;

// {
//   /* <div className='hvr-shadow-radial'>
//               <a
//                 href={`https://ticketsystem.finehhdigital.com/?token=${localStorage.getItem(
//                   "token"
//                 )}`}
//                 style={{
//                   color: "black",
//                   textDecoration: "none",
//                   fontFamily: "sans-serif",
//                 }}
//               >
//                 <div className="col-lg-3 col-md-6  py-2 defect-handling-card">
//                   <div
//                     className="feature_item"
//                     style={{
//                       background: "linear-gradient(100deg, #FDF4E3, #7FB5B5)",
//                     }}
//                   >
//                     <h4 className="text-Card">Tickets</h4>
//                     <p
//                       style={{
//                         textAlign: "center",
//                         fontSize: "15px",
//                         height: "10vh",
//                       }}
//                     >
//                       Create Ticket
//                     </p>
//                   </div>
//                 </div>
//               </a>
//               </div> */
// }

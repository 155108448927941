import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import "./userProfile.css";
import { userRequest } from "../../api-routes/ApiRoutes";

const UserProfile = () => {
  const history = useHistory();
  // console.clear()
  const [newPass, setNewPass] = React.useState("");
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getUserData();
    const getitem = localStorage.getItem("token");
    const decoded = jwt.decode(getitem);
    setUserId(decoded.id);
  }, [userId]);
  const getUserData = async () => {
    let fd = await userRequest.post(
      `users/userIndividualDatabyID`,
      { id: userId }
    );
    setUserData(fd.data.data);
  };

  const handleUploadPic = () => {

    var fileInput = document.getElementById("profileImg");

    if (fileInput.files[0] == null) {
      toast.warn("Please add file!");
      return;
    }
    let formData = new FormData();
    formData.append("imageFile", fileInput.files[0]);
    formData.append("id", userId);

    userRequest({
      method: "post",
      url: `users/uploadImage`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        getUserData();
      })
      .catch((error) => {
        console.log("error from upload photo ===>", error);
      });

    document.getElementById("profileImg").value = "";
  };
  //handle logout
  const HandleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    if (localStorage.getItem("token")) {
      console.log("here");
    } else {
      history.push("/signin");
    }
  };
  const [imgSelected, setImageSelected] = useState(false);
  // Image cheacker
  const checkImage = () => {
    setImageSelected(true);
  };
  // Password reset
  const ChangePassword = async (e) => {
    e.preventDefault();
    let sendData = await userRequest.post(
      `password/updatePassword`,
      {
        userId: userId,
        newPass: newPass,
      }
    );
    if (sendData.data.message) {
      toast.success(sendData.data.message);
      HandleLogout();
      setNewPass("");
    } else {
      toast.error("Reset Error");
    }
  };

  return (
    <div style={{ height: "auto" }}>
      <section className="home_banner_area">
        <div
          className="px-4 main-container banner_inner  "
          style={{ height: "100vh" }}
        >
          <div className="container1">
            <div className="main-body">
              <div className="row gutters-sm">
                <div className="col-md-4 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      {userData.map((user, i) => (
                        <div
                          key={i}
                          className="d-flex flex-column align-items-center text-center"
                        >
                          {user.profileUrl ? (
                            <img
                              src={user.profileUrl}
                              alt="Admin"
                              className=""
                              width={150}
                              height={150}
                            />
                          ) : (
                            <img
                              src="/icon/dummyuser.jpg"
                              alt="Admin"
                              className="rounded-circle"
                              width={150}
                            />
                          )}
                          <div className="mt-3">
                            <h4>{user.username}</h4>
                          </div>
                        </div>
                      ))}
                      <nav>
                        <div
                          className="nav flex-column nav-pills"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            User Details
                          </button>
                          <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            Reset Password
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="card mb-3">
                        <div className="p-3 w-100">
                          <h2 className="text-center">About</h2>
                        </div>
                        {userData?.map((user, i) => (
                          <div className="card-body pb-0" key={i}>
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Full Name:</h6>
                              </div>
                              <div className="col-sm-9 text-secondary">
                                {user.username}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Email:</h6>
                              </div>
                              <div className="col-sm-9 text-secondary">
                                {user.email}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Role:</h6>
                              </div>

                              <div className="col-sm-9 text-secondary">
                                {user.isAdmin}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6
                                  className="mb-0"
                                  style={{ width: "fit-content" }}
                                >
                                  Department:
                                </h6>
                              </div>

                              <div className="col-sm-9 text-secondary">
                                {user.DepartName}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Assigned To:</h6>
                              </div>
                              <div className="assignedRole mt-3">
                                <div>{user.AssignedBOS ? "BOS" : null}</div>
                                <div>{user.AssignedECIL ? "ECIL" : null}</div>
                                <div>
                                  {user.AssignedEDefect ? "E-Defect" : null}
                                </div>
                                <div>{user.AssignedOFS ? "OFS" : null}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="card-body pt-0">
                          <h5 className="text-center py-2">
                            Upload Profile Picture
                          </h5>
                          <div className="row mt-2">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Profile Image:</h6>
                            </div>
                            <div className="col-sm-9 text-secondary d-flex justify-content-center w-100 mt-5">
                              <label className='label'>
                                <input
                                  type="file"
                                  id="profileImg"
                                  onChange={checkImage}
                                />
                                <span>Choose Profile</span>
                              </label>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col-sm-12">
                              {/* <a className="btn btn-info " href="#">Submit</a> */}
                              {imgSelected ? (
                                <button
                                  className="btn btn-success"
                                  onClick={handleUploadPic}
                                >
                                  Upload
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div className="card mb-3">
                        <div className="p-3 w-100">
                          <h2 className="text-center">Rest Password</h2>
                        </div>
                        <div className="card-body pb-0">
                          <form className="g-3 needs-validation" novalidate>
                            <div className="row">
                              <div className="col-md-12">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label"
                                >
                                  New Password:
                                </label>
                                <input
                                  type="password"
                                  value={newPass}
                                  onChange={(e) => setNewPass(e.target.value)}
                                  className="form-control w-100"
                                  style={{ border: "1px solid #b5b4b4" }}
                                  id="validationCustom02"
                                  placeholder="Enter your new password"
                                  required
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                              </div>
                            </div>
                            <div className="row py-2">
                              <div className="col-sm-12">
                                <button
                                  className="btn btn-success"
                                  onClick={ChangePassword}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    // </div >
  );
};

export default UserProfile;
